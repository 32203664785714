@charset "utf-8";

// Define defaults for each variable.

@font-face {
  font-family: "Raleway";
  src: url('/assets/fonts/Raleway/Raleway-VariableFont_wght.ttf');
  font-weight: 1 400;
}

@font-face {
  font-family: "Nunito";
  src: url('/assets/fonts/Nunito/Nunito-Light.ttf');
  font-weight: 1 400;
}

@font-face {
  font-family: "Nunito";
  src: url('/assets/fonts/Nunito/Nunito-LightItalic.ttf');
  font-weight: 1 400;
  font-style: italic;
}

@font-face {
  font-family: "Arsenal";
  src: url('/assets/fonts/Arsenal/Arsenal-Regular.ttf');
  font-weight: 1 400;
}


$h1-font-family: 'Raleway';

$base-font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   18px !default;
$base-font-weight: 700 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.4444 !default;

$ua-font-family:   "Arsenal";
$ua-font-size:     32px !default;
$ua-font-weight:   400 !default;
$ua-line-height: 1.0 !default;


$spacing-unit:     30px !default;

$text-color:       rgba(0, 0, 0, 0.87) !default;
$background-color: #fdfdfd !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1000px !default;

$on-palm:          800px !default;
$on-laptop:        1000px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
